import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
export default function BottomImage(): ReactElement {
    return (
        <div className={`w-full md:w-3/5 ${styles["mainBannerWrapper"]}`}>
            <Image
                src="/storagecontainerprices/assets/images/bottomImg1Opt.png"
                className={styles["background-img"]}
                alt="StorageContainerprices - 4 Benefits of Steel Storage Containers"
                layout="intrinsic"
                priority
                width={700}
                height={940}
            />
        </div>
    );
}
