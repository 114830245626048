import React from "react";

function Security() {
    return (
        <svg
            width="42"
            height="50"
            viewBox="0 0 42 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_50_9066)">
                <g clipPath="url(#clip1_50_9066)">
                    <path
                        d="M14.5003 8.70801C11.6191 9.49049 8.63318 9.82607 5.64851 9.70281C4.73717 17.3044 4.52818 24.6917 6.7397 31.338C8.98961 38.0977 13.7908 44.1567 23.0002 49.0003C32.2103 44.1582 37.0101 38.0992 39.26 31.3394C41.4715 24.6931 41.2625 17.3058 40.3512 9.70421C37.367 9.82731 34.3815 9.49176 31.5008 8.70942C28.5471 7.90992 25.7179 6.65708 23.0002 5.00098C20.2832 6.65708 17.4554 7.90992 14.5003 8.70942V8.70801Z"
                        fill="#D7F9FB"
                    />
                </g>
                <path
                    d="M2.15927 5.06625C5.30513 5.31995 8.47189 5.03259 11.5174 4.21706C14.5091 3.41348 17.3849 2.10483 20.1599 0.350211L20.6083 0.0652537C20.6751 0.0220484 20.7533 -0.000976562 20.8332 -0.000976562C20.9132 -0.000976562 20.9914 0.0220484 21.0582 0.0652537L21.5065 0.350211C24.2809 2.10483 27.1567 3.41348 30.149 4.21706C33.1946 5.03259 36.3613 5.31995 39.5072 5.06625L40.2946 5.00854C40.4012 5.00019 40.5069 5.03254 40.5899 5.09888C40.6729 5.16521 40.7266 5.26042 40.7401 5.36474L40.8408 6.13981C41.9273 14.5354 42.2808 22.745 39.7738 30.2144C36.6003 39.6686 29.7818 45.4853 21.0263 49.9534C20.9673 49.9843 20.9015 50.0004 20.8347 50.0004C20.7679 50.0004 20.7021 49.9843 20.6431 49.9534C11.8854 45.4853 5.06907 39.6686 1.89487 30.2109C-0.611457 22.7414 -0.256515 14.5318 0.828596 6.13626L0.928557 5.36117C0.942173 5.25693 0.996009 5.16182 1.07893 5.09552C1.16185 5.02922 1.26751 4.99681 1.37405 5.00497L2.16072 5.06625H2.15927ZM12.1708 6.5758C9.23564 7.36635 6.19374 7.70543 3.15311 7.581C2.22446 15.267 2.01149 22.7279 4.26502 29.4436C6.55766 36.2747 11.4486 42.3963 20.8329 47.2891C30.2179 42.3963 35.1088 36.2747 37.4015 29.4436C39.655 22.7279 39.442 15.2634 38.5126 7.58241C35.4719 7.70634 32.43 7.3668 29.4949 6.5758C26.4852 5.76795 23.6022 4.50202 20.8329 2.82861C18.0643 4.50202 15.1828 5.76795 12.1708 6.5758Z"
                    fill="#00A8B1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.8836 21.0253H24.7828V17.0181C24.7828 15.4066 23.7941 14.3751 22.5279 13.922C21.9854 13.7311 21.4133 13.6347 20.8372 13.637C20.2611 13.6348 19.689 13.7313 19.1465 13.922C17.8767 14.3758 16.8836 15.4088 16.8836 17.0174V21.0253ZM26.9618 21.13C27.5487 21.2958 28.0652 21.6445 28.4334 22.1237C28.8016 22.6028 29.0018 23.1866 29.0038 23.7872V31.3585C29.0017 32.0904 28.7051 32.7917 28.1789 33.3092C27.6527 33.8268 26.9396 34.1184 26.1954 34.1205H15.4746C14.7304 34.1184 14.0172 33.8268 13.4909 33.3093C12.9645 32.7918 12.6678 32.0905 12.6655 31.3585V23.7872C12.6676 23.1865 12.8678 22.6027 13.2362 22.1235C13.6046 21.6443 14.1211 21.2957 14.7083 21.13V17.0181C14.7083 14.3587 16.3323 12.6568 18.4098 11.9138C19.1898 11.6391 20.0123 11.4997 20.8408 11.502C21.6693 11.4991 22.4919 11.6382 23.2718 11.9131C25.3457 12.6547 26.9661 14.3551 26.9661 17.0181V21.13H26.9618ZM20.8328 24.2424C21.0843 24.2423 21.3333 24.291 21.5657 24.3856C21.7981 24.4801 22.0092 24.6188 22.1871 24.7937C22.3649 24.9685 22.506 25.1761 22.6023 25.4046C22.6985 25.6331 22.7481 25.878 22.7481 26.1253C22.7482 26.4814 22.6457 26.8303 22.4523 27.1314C22.259 27.4325 21.9828 27.6736 21.6557 27.8265L22.3975 30.909H19.2689L20.0107 27.8265C19.6836 27.6736 19.4074 27.4325 19.2141 27.1314C19.0207 26.8303 18.9182 26.4814 18.9183 26.1253C18.9183 25.6259 19.12 25.147 19.4791 24.7939C19.8381 24.4408 20.3251 24.2424 20.8328 24.2424Z"
                    fill="#00A8B1"
                />
            </g>
            <defs>
                <clipPath id="clip0_50_9066">
                    <rect width="41.6667" height="50" fill="white" />
                </clipPath>
                <clipPath id="clip1_50_9066">
                    <rect
                        width="36"
                        height="44"
                        fill="white"
                        transform="translate(5 5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Security;
