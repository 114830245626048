import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import BottomImage from "../bottomImage";
import Security from "./icons/security";
import Storage from "./icons/storage";
import Durable from "./icons/durable";
import Transportation from "./icons/transportation";

const benefitsList = [
    {
        title: "Storage",
        description:
            "The most obvious advantage of steel containers is their spacious interior, allowing for the storage of a substantial quantity of items for extended periods. These containers are particularly useful for various needs, such as securely archiving business documents, storing furniture, and landscaping materials, or holding inventory for your pop-up store.",
        icon: <Storage />,
    },
    {
        title: "Security",
        description:
            "An additional benefit of quality steel shipping containers is their enhanced security. These units can be equipped with highly effective locking systems, ensuring year-round protection of your valuables. Most reputable manufacturers of steel storage containers include such security features. However, you also have the option for custom modifications to further secure every aspect of your unit, providing added peace of mind.",
        icon: <Security />,
    },
    {
        title: "Durable",
        description:
            "Steel storage containers are remarkably resilient against harsh weather conditions and external damage, ensuring the safety of your items. With regular maintenance, these robust containers can last about 20 years, offering long-term reliability. Using these strong steel units alleviates worries about the safety of your items, even in storm-prone areas.",
        icon: <Durable />,
    },
    {
        title: "Transportation",
        description:
            "Having a single storage location for your items is convenient, but if you need to relocate or transport them elsewhere, used shipping containers offer a versatile solution. You can load them with your items and easily ship them to any destination you choose.",
        icon: <Transportation />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <div
            className={`flex flex-col xl:flex-row flex-nowrap items-center justify-between mb-12 ${styles["DoYouKnowWrapper"]}`}
        >
            <div className="w-full md:w-3/5 ">
                <div
                    className={` font-bold mb-8 text-3xl xl:text-4xl pt-11 md:pt-24  ${styles["title"]}`}
                >
                    Benefits of Steel Storage Containers
                </div>
                <p className={` text-base lg:text-lg mb-6 ${styles["brief"]}`}>
                    If your business involves transporting large products to
                    different destinations or needing long-term storage for
                    valuable items, steel shipping containers are an ideal
                    solution. They offer several advantages for these purposes,
                    and here are some key benefits to consider.
                </p>

                {benefitsList.map((benefit) => (
                    <div key={benefit.title}>
                        <div className={`flex items-center mb-5`}>
                            <div className={`w-16`}>{benefit.icon}</div>
                            <div
                                className={`font-bold  text-xl xl:text-3xl  ${styles["title2"]}`}
                            >
                                {benefit.title}
                            </div>
                        </div>
                        <div
                            className={`text-base md:text-lg mb-6 ${styles["brief"]}`}
                        >
                            {benefit.description}
                        </div>
                    </div>
                ))}
            </div>
            <BottomImage />
        </div>
    );
}
