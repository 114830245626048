import React from "react";

function Storage() {
    return (
        <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="5" y="26" width="43" height="23" fill="#D7F9FB" />
            <g clipPath="url(#clip0_50_9054_1)">
                <rect
                    x="22.5312"
                    y="9.53125"
                    width="4.15424"
                    height="4.15424"
                    transform="rotate(-45 22.5312 9.53125)"
                    fill="#D7F9FB"
                />
                <path
                    d="M7.33379 45.8863H4.87038C4.66959 45.8863 4.47701 45.8065 4.33503 45.6645C4.19305 45.5225 4.11328 45.3298 4.11328 45.129V27.9399C4.11328 27.7391 4.19305 27.5465 4.33503 27.4044C4.47701 27.2624 4.66959 27.1826 4.87038 27.1826H7.33379C7.53459 27.1826 7.72716 27.2624 7.86914 27.4044C8.01113 27.5465 8.09089 27.7391 8.09089 27.9399C8.09089 28.1408 8.01113 28.3334 7.86914 28.4754C7.72716 28.6175 7.53459 28.6972 7.33379 28.6972H5.63032V44.3712H7.33379C7.53459 44.3712 7.72716 44.451 7.86914 44.593C8.01113 44.735 8.09089 44.9277 8.09089 45.1285C8.09089 45.3294 8.01113 45.522 7.86914 45.664C7.72716 45.806 7.53459 45.8858 7.33379 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M13.7274 45.8863H11.2639C11.0631 45.8863 10.8706 45.8065 10.7286 45.6645C10.5866 45.5225 10.5068 45.3298 10.5068 45.129V27.9399C10.5068 27.7391 10.5866 27.5465 10.7286 27.4044C10.8706 27.2624 11.0631 27.1826 11.2639 27.1826H13.7274C13.9281 27.1826 14.1207 27.2624 14.2627 27.4044C14.4047 27.5465 14.4845 27.7391 14.4845 27.9399C14.4845 28.1408 14.4047 28.3334 14.2627 28.4754C14.1207 28.6175 13.9281 28.6972 13.7274 28.6972H12.0239V44.3712H13.7274C13.9281 44.3712 14.1207 44.451 14.2627 44.593C14.4047 44.735 14.4845 44.9277 14.4845 45.1285C14.4845 45.3294 14.4047 45.522 14.2627 45.664C14.1207 45.806 13.9281 45.8858 13.7274 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M20.1204 45.8863H17.656C17.4552 45.8863 17.2627 45.8065 17.1207 45.6645C16.9787 45.5225 16.8989 45.3298 16.8989 45.129V27.9399C16.8989 27.7391 16.9787 27.5465 17.1207 27.4044C17.2627 27.2624 17.4552 27.1826 17.656 27.1826H20.1204C20.3212 27.1826 20.5138 27.2624 20.6557 27.4044C20.7977 27.5465 20.8775 27.7391 20.8775 27.9399C20.8775 28.1408 20.7977 28.3334 20.6557 28.4754C20.5138 28.6175 20.3212 28.6972 20.1204 28.6972H18.4136V44.3712H20.1204C20.3212 44.3712 20.5138 44.451 20.6557 44.593C20.7977 44.735 20.8775 44.9277 20.8775 45.1285C20.8775 45.3294 20.7977 45.522 20.6557 45.664C20.5138 45.806 20.3212 45.8858 20.1204 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M26.5111 45.8863H24.0506C23.8498 45.8863 23.6572 45.8065 23.5152 45.6645C23.3732 45.5225 23.2935 45.3298 23.2935 45.129V27.9399C23.2935 27.7391 23.3732 27.5465 23.5152 27.4044C23.6572 27.2624 23.8498 27.1826 24.0506 27.1826H26.5111C26.7119 27.1826 26.9045 27.2624 27.0465 27.4044C27.1885 27.5465 27.2682 27.7391 27.2682 27.9399C27.2682 28.1408 27.1885 28.3334 27.0465 28.4754C26.9045 28.6175 26.7119 28.6972 26.5111 28.6972H24.8077V44.3712H26.5111C26.7119 44.3712 26.9045 44.451 27.0465 44.593C27.1885 44.735 27.2682 44.9277 27.2682 45.1285C27.2682 45.3294 27.1885 45.522 27.0465 45.664C26.9045 45.806 26.7119 45.8858 26.5111 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M32.9061 45.8863H30.4426C30.2419 45.8863 30.0493 45.8065 29.9073 45.6645C29.7653 45.5225 29.6855 45.3298 29.6855 45.129V27.9399C29.6855 27.7391 29.7653 27.5465 29.9073 27.4044C30.0493 27.2624 30.2419 27.1826 30.4426 27.1826H32.9061C33.1069 27.1826 33.2994 27.2624 33.4414 27.4044C33.5834 27.5465 33.6632 27.7391 33.6632 27.9399C33.6632 28.1408 33.5834 28.3334 33.4414 28.4754C33.2994 28.6175 33.1069 28.6972 32.9061 28.6972H31.1997V44.3712H32.9061C33.1069 44.3712 33.2994 44.451 33.4414 44.593C33.5834 44.735 33.6632 44.9277 33.6632 45.1285C33.6632 45.3294 33.5834 45.522 33.4414 45.664C33.2994 45.806 33.1069 45.8858 32.9061 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M39.2991 45.8863H36.8347C36.6339 45.8863 36.4414 45.8065 36.2994 45.6645C36.1574 45.5225 36.0776 45.3298 36.0776 45.129V27.9399C36.0776 27.7391 36.1574 27.5465 36.2994 27.4044C36.4414 27.2624 36.6339 27.1826 36.8347 27.1826H39.2991C39.4999 27.1826 39.6925 27.2624 39.8344 27.4044C39.9764 27.5465 40.0562 27.7391 40.0562 27.9399C40.0562 28.1408 39.9764 28.3334 39.8344 28.4754C39.6925 28.6175 39.4999 28.6972 39.2991 28.6972H37.5918V44.3712H39.2991C39.4999 44.3712 39.6925 44.451 39.8344 44.593C39.9764 44.735 40.0562 44.9277 40.0562 45.1285C40.0562 45.3294 39.9764 45.522 39.8344 45.664C39.6925 45.806 39.4999 45.8858 39.2991 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M45.6917 45.8863H43.2283C43.0275 45.8863 42.8349 45.8065 42.6929 45.6645C42.551 45.5225 42.4712 45.3298 42.4712 45.129V27.9399C42.4712 27.7391 42.551 27.5465 42.6929 27.4044C42.8349 27.2624 43.0275 27.1826 43.2283 27.1826H45.6917C45.8925 27.1826 46.0851 27.2624 46.2271 27.4044C46.369 27.5465 46.4488 27.7391 46.4488 27.9399C46.4488 28.1408 46.369 28.3334 46.2271 28.4754C46.0851 28.6175 45.8925 28.6972 45.6917 28.6972H43.9854V44.3712H45.6917C45.8925 44.3712 46.0851 44.451 46.2271 44.593C46.369 44.735 46.4488 44.9277 46.4488 45.1285C46.4488 45.3294 46.369 45.522 46.2271 45.664C46.0851 45.806 45.8925 45.8858 45.6917 45.8858V45.8863Z"
                    fill="#00A8B1"
                />
                <path
                    d="M50.1127 23.0672H43.7246C43.7023 23.0428 43.6785 23.0199 43.6532 22.9986L29.0156 10.7742L30.3646 9.42473C30.435 9.35441 30.4907 9.27091 30.5288 9.17902C30.5669 9.08713 30.5864 8.98864 30.5864 8.88917C30.5864 8.7897 30.5669 8.69121 30.5288 8.59932C30.4907 8.50743 30.435 8.42394 30.3646 8.35361L26.0553 4.04309V0.757311C26.0553 0.556459 25.9755 0.363834 25.8336 0.221811C25.6916 0.0797873 25.499 0 25.2982 0C25.0974 0 24.9048 0.0797873 24.7629 0.221811C24.6209 0.363834 24.5411 0.556459 24.5411 0.757311V4.04309L20.2323 8.35361C20.1619 8.42394 20.1062 8.50743 20.0681 8.59932C20.03 8.69121 20.0104 8.7897 20.0104 8.88917C20.0104 8.98864 20.03 9.08713 20.0681 9.17902C20.1062 9.27091 20.1619 9.35441 20.2323 9.42473L21.7081 10.9005L7.22147 22.9986C7.19619 23.0199 7.17232 23.0428 7.15002 23.0672H0.761984C0.561188 23.0672 0.368616 23.147 0.226632 23.289C0.084648 23.431 0.00488281 23.6237 0.00488281 23.8245V49.2418C0.00488281 49.4426 0.084648 49.6352 0.226632 49.7773C0.368616 49.9193 0.561188 49.9991 0.761984 49.9991H50.1155C50.3163 49.9991 50.5089 49.9193 50.6509 49.7773C50.7928 49.6352 50.8726 49.4426 50.8726 49.2418V23.8245C50.8726 23.7248 50.8529 23.6261 50.8147 23.5341C50.7765 23.442 50.7205 23.3584 50.6499 23.288C50.5792 23.2177 50.4955 23.1619 50.4033 23.124C50.3111 23.0861 50.2123 23.0668 50.1127 23.0672V23.0672ZM25.2958 5.42897L28.7563 8.88988L25.2958 12.3508L23.3558 10.4102L21.8373 8.8913L25.2958 5.42897ZM22.7808 11.9773L24.7607 13.9577C24.831 14.028 24.9144 14.0839 25.0063 14.1219C25.0982 14.16 25.1966 14.1796 25.2961 14.1796C25.3955 14.1796 25.494 14.16 25.5858 14.1219C25.6777 14.0839 25.7612 14.028 25.8315 13.9577L27.9381 11.851L41.371 23.0686H9.4994L22.7808 11.9773ZM49.3551 48.4859H1.51577V24.5832H49.3551V48.4859Z"
                    fill="#00A8B1"
                />
            </g>
            <defs>
                <clipPath id="clip0_50_9054_1">
                    <rect width="50.8696" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Storage;
